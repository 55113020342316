import { gsap } from "gsap";
import objectFitImages from "object-fit-images";
import Animations from "./Animations";
import BlockAnimation from "./BlockAnimation";
import Counter from "./Counter";
import CustomerLogos from "./CustomerLogos";
import Expandable from "./Expandable";
import HeaderBar from "./HeaderBar";
import ImageSlider from "./ImageSlider";
import Menu from "./Menu";
import Navigator from "./Navigator";
import ScrollToButtons from "./ScrollToButtons";
import ScrollEvents from "./ScrollEvents";
import Select from "./Select";
import StoryItem from "./StoryItem";
import Video from "./Video.js.erb";
import WebinarItem from "./WebinarItem";
import ImmediateOpportunities from "./ImmediateOpportunities";
import SignupModal from "./SignupModal";
import U from "./Utilities";

class Controller {
  constructor() {
    this.scrollEvents = new ScrollEvents();
    this.dom = {
      headerBar: document.getElementById("header-bar"),
      bg: document.querySelector(".header__background"),
      container: document.getElementById("container"),
      content: document.getElementById("content"),
      menu: document.getElementById("menu"),
      navigator: document.getElementById("navigator"),
      header: document.querySelector(".header"),
      sliders: document.querySelectorAll(".image-slider"),
      blocks: document.querySelectorAll(".layout-block--animated"),
      stories: document.querySelectorAll(".story-item"),
      faqs: document.querySelectorAll(".faq-item"),
      links: document.querySelectorAll("a"),
      signupItems: document.querySelectorAll(".detail-item--signup"),
      stats: document.querySelectorAll(".stats"),
      timelines: document.querySelectorAll(".timeline-event"),
      logos: document.querySelectorAll(".customer-logos"),
      selects: document.querySelectorAll("select"),
      footer: document.getElementById("footer"),
      video: document.querySelector(".video-modal"),
      signupModal: document.querySelector(".signup-modal"),
      webinars: document.querySelectorAll(".webinar-item")
    };
    U.autobind(this);
  }

  init() {
    // Header Bar
    this.headerBar = new HeaderBar(this.dom.headerBar);
    this.scrollEvents.add(this.headerBar.update);

    if (U.client.isIE() || U.client.isEdge()) {
      if (U.client.isIE11()) {
        document.documentElement.classList.add("ie11");
      }

      objectFitImages();
    }

    this.menu = new Menu(this.dom.menu);

    this.scrollEvents.add(this.menu.recalculateHeight);

    // Buttons which scroll to different places on a page
    ScrollToButtons();

    // Image Sliders
    this.dom.sliders.forEach(el => {
      el.slider = new ImageSlider(el);

      this.scrollEvents.trigger(el, "60%", el.slider.showCaptions);
    });

    // TODO - include this (and implement it properly) when
    // we decide we want pagingation:
    // Load More
    // document.querySelectorAll('.load-more').forEach((el) => {
    // 	const button = document.createElement('button');
    // 	button.classList.add('load-more__button', 'button', 'button--border-alt');
    // 	button.textContent = 'Load more';

    // 	if (el.nextSibling) {
    // 		el.parentNode.insertBefore(button, el.nextSibling);
    // 	} else {
    // 		el.parentNode.appendChild(button);
    // 	}
    // });

    // Block Animations
    const handleBlockIntersect = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.target.anim.paused() && entry.intersectionRatio > 0) {
          // Initial play
          entry.target.anim.play();
        } else {
          if (entry.intersectionRatio >= 0.9) {
            // Scrolled back
            entry.target.anim.play();
            if (
              entry.target.anim.childAnim &&
              entry.target.anim.progress() >
                entry.target.anim.labels["childEntry"]
            ) {
              entry.target.anim.childAnim.play();
            }
          } else {
            // Scrolled off page
            if (entry.target.anim.childAnim) {
              entry.target.anim.childAnim.pause();
            }
          }
        }
      });
    };
    const handleBlockReset = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio === 0) {
          // Left viewport entirely
          if (
            entry.target.anim.childAnim &&
            entry.target.anim.childAnim.progress() > 0
          ) {
            entry.target.anim.childAnim.seek(0);
          }
        }
      });
    };
    const blockObserver = new IntersectionObserver(handleBlockIntersect, {
      threshold: [0.25, 0.99],
    });
    const resetBlockObserver = new IntersectionObserver(handleBlockReset, {
      threshold: 0,
    });
    this.dom.blocks.forEach(el => {
      if (window.innerWidth <= 667 && el.querySelector(".image-frame svg")) {
        const anim = el.querySelector(".image-frame svg");
        anim.classList.add("animation--is-visible");
      }

      const frame = el.querySelector(".image-frame");
      frame.anim = new BlockAnimation(el);
      blockObserver.observe(frame);
      resetBlockObserver.observe(frame);
    });

    // Story Items
    this.dom.stories.forEach(el => {
      el.story = new StoryItem(el);
    });

    // Webinar Items
    this.dom.webinars.forEach(el => {
      el.webinar = new WebinarItem(el);
    });

    if (window.location.pathname.endsWith("/immediate-opportunities")) {
      ImmediateOpportunities(window.document);
    }

    // FAQs
    this.dom.faqs.forEach(el => {
      const answer = el.querySelector(".faq-item__answer");

      el.Expandable = new Expandable(answer, {
        duration: 0.5,
        ease: "Power3.easeInOut",
      });

      el.addEventListener("click", () => {
        this.dom.faqs.forEach(item => {
          if (item !== el) {
            item.Expandable.close();
          }
        });
      });
    });

    this.dom.links.forEach(el => {
      if (!el.getAttribute("target")) {
        el.addEventListener("click", this._hijackClick);
      }
    });

    this.dom.stats.forEach(stats => {
      stats.querySelectorAll(".stats__item").forEach(item => {
        item.timeline = gsap.timeline({ paused: true });
        item.timeline.fromTo(
          item.querySelector(".stats__description"),
          1,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            delay: 0.75,
            ease: "Expo.easeOut",
          }
        );

        const value = item.querySelector(".stats__value");
        item.Counter = new Counter(value);
      });

      this.scrollEvents.trigger(stats, "85%", () => {
        stats.querySelectorAll(".stats__item").forEach((item, i) => {
          window.setTimeout(() => {
            item.Counter.run();
            item.timeline.play();
          }, i * 600);
        });
      });
    });

    this.dom.timelines.forEach(event => {
      this.scrollEvents.trigger(
        event.querySelector(".timeline-event__dot"),
        "50%",
        () => {
          event.classList.add("timeline-event--is-active");
        }
      );

      const progress = event.querySelector(".timeline-event__progress");
      let scale = 0;

      this.scrollEvents.add(() => {
        const rect = U.rect(progress);
        const target = window.innerHeight / 2;

        if (rect.top < target && scale !== 1) {
          const value = Math.min(
            (target - rect.top) / progress.clientHeight,
            1
          );

          if (value > scale) {
            progress.style.transform = `scaleY(${value})`;
            scale = value;
          }
        }

        return scale >= 1;
      });
    });

    this.dom.logos.forEach(logos => {
      logos.CustomerLogos = new CustomerLogos(logos);

      this.scrollEvents.trigger(logos, "100%", logos.CustomerLogos.start);
    });

    this.dom.selects.forEach(el => {
      el.select = new Select(el);
    });

    if (this.dom.bg) {
      this.scrollEvents.add(scrollPos => {
        if (scrollPos < this.dom.bg.clientHeight) {
          this.dom.bg.style.transform = `translate3d(0, ${scrollPos *
            0.15}px, 0)`;

          if (window.innerWidth > 1024) {
            this.dom.bg.style.filter = `blur(${(scrollPos /
              this.dom.bg.clientHeight) *
              5}px)`;
          }
        }
      });
    }

    if (this.dom.signupItems.length) {
      const track = document.querySelector(".detail-item__track");
      const progress = track.children[0];

      track.style.left = `-${U.rect(this.dom.signupItems[0]).left -
        U.rect(
          document.getElementsByClassName("buyer-signup-details-section")[0]
        ).left}px`;

      track.style.width = `${
        document.getElementById("container").clientWidth
      }px`;

      U.resize().add(() => {
        track.style.left = `-${U.rect(this.dom.signupItems[0]).left -
          U.rect(
            document.getElementsByClassName("buyer-signup-details-section")[0]
          ).left}px`;
        track.style.width = `${this.dom.container.clientWidth}px`;
      });

      this.scrollEvents.trigger(track, "75%", () => {
        const triggers = [];
        const offset = U.rect(this.dom.container).left;

        this.dom.signupItems.forEach(item => {
          const rect = U.rect(item);

          triggers.push(rect.left - offset);
        });

        const tween = gsap.to(progress, {
          duration: 2.5,
          xPercent: 100,
          ease: "Linear.easeNone",
          onUpdate: () => {
            if (tween.progress() * track.clientWidth > triggers[0]) {
              this.dom.signupItems[
                this.dom.signupItems.length - triggers.length
              ].classList.add("detail-item--pulse");
              triggers.shift();
            }
          },
        });
      });
    }

    if (this.dom.navigator) {
      this.navigator = new Navigator(this.dom.navigator);

      this.scrollEvents.add(this.navigator.update);
    }

    if (this.dom.video) {
      this.video = new Video(this.dom.video);
    }

    // Signup modal
    new SignupModal(this.dom.signupModal);
  }

  showContent() {
    this.headerBar.ready();

    gsap.to(this.dom.content, {
      duration: 1.3,
      opacity: 1,
      ease: "Power1.easeOut",
      onComplete: () => {
        this.headerBar.ready();
        this.scrollEvents.start();

        if (this.hero) {
          this.hero.timeline.play();
        }
      },
    });
  }

  _hideContent(href) {
    const rect = U.rect(this.dom.footer);
    const duration = 0;

    if (U.client.isMobile()) {
      this.headerBar.hide();
    }

    gsap.to([this.dom.content, this.dom.footer], {
      duration: duration,
      y:
        rect.top < window.innerHeight && rect.top > 0
          ? window.innerHeight - rect.top
          : 0,
      ease: "Cubic.easeInOut",
      onComplete: () => {
        document.querySelector('main').style.transform = "none";
        this.dom.footer.style.transform = "none";        
      }
    });

    gsap.to(this.dom.content, {
      duration: duration,
      opacity: 0,
      ease: "Power3.easeOut",
      onComplete: () => {
        if (href) {
          window.location.href = href;
        }
      },
    });
  }

  _hijackClick(e) {
    const href = e.currentTarget.href;

    if (
      !e.altKey &&
      !e.ctrlKey &&
      !e.metaKey &&
      href.indexOf("#") < 0 &&
      href.indexOf("mailto:") < 0
    ) {
      e.preventDefault();

      this._hideContent(href);

      return false;
    }

    return true;
  }
}

export default Controller;
