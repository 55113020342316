import U from "./Utilities";
import { gsap } from "gsap";
import HeaderBar from "./HeaderBar";

class SignupModal {
  constructor(el) {
    U.autobind(this);

    this.dom = {
      el,
      overlay: el.querySelector(".signup-modal__overlay"),
      inner: el.querySelector(".signup-modal__inner"),
      triggers: document.querySelectorAll(".signup-modal-trigger"),
      hide: el.querySelector(".signup-modal__close"),
      headerBar: document.getElementById("header-bar")
    };

    this.headerBar = new HeaderBar(this.dom.headerBar);

    this.dom.el.remove();
    this.dom.el.classList.remove("signup-modal--is-hidden");

    this.dom.triggers.forEach(trigger => {
      trigger.addEventListener("click", this.show);
    });
    this.dom.hide.addEventListener("click", this.hide);

    if (window.location.hash === "#signup") {
      this.show();
    }
  }

  show() {
    const timeline = gsap.timeline({
      paused: true,
    });

    timeline
      .fromTo(
        this.dom.overlay,
        {
          opacity: 0,
        },
        {
          duration: 0.25,
          opacity: 1,
          ease: "Power1.easeOut",
        }
      )
      .fromTo(
        this.dom.hide,
        {
          opacity: 0,
        },
        {
          duration: 0.25,
          opacity: 1,
          ease: "Power1.easeOut",
        },
        "-=0.75"
      );

    document.body.appendChild(this.dom.el);
    document.addEventListener("keydown", this.keydownHandler);
    this.dom.el.addEventListener("click", this.clickHandler);

    timeline.play();
  }

  hide() {
    const timeline = gsap.timeline();

    timeline.fromTo(
      [this.dom.overlay, this.dom.hide],
      {
        opacity: 1,
      },
      {
        duration: 0.25,
        opacity: 0,
        ease: "Power1.easeOut",
        onComplete: () => {
          this.dom.el.remove();
          this.headerBar.show();
          document.querySelector('main').style.opacity = "1";
        },
      }
    );

    document.removeEventListener("keydown", this.keydownHandler);
    this.dom.el.removeEventListener("click", this.clickHandler);
  }

  keydownHandler(e) {
    if (e.keyCode === 27) {
      this.hide();
    }
  }

  clickHandler(e) {
    const target = e.target;
    if (target === this.dom.overlay) {
      this.hide();
    }
  }
}

export default SignupModal;
